<script setup>
import {computed, ref, watchEffect} from 'vue'
import * as iconsArr from 'country-flag-icons/string/3x2'
import { hasFlag } from 'country-flag-icons'


const props = defineProps({code: {}});

const innerCode = computed(() => {
  if(typeof props.code === 'string'){
    if(props.code.toUpperCase() === 'UK'){
      return 'GB';
    }
  }
  return props.code;
});

const innerHtmlSvg = ref(''); //html внутри svg
const attributesSvg = ref({}); //html внутри svg
const showSvg = computed(() => {
  if(typeof innerCode.value === 'string'){
    return hasFlag(innerCode.value.toUpperCase());
  }else{
    return false;
  }
});

watchEffect(() => {
  if(showSvg.value){
    let svgHtml = iconsArr[innerCode.value.toUpperCase()];
    let svgElemOuter = document.createElement('div');
    svgElemOuter.innerHTML = svgHtml;
    let svgElem = svgElemOuter.firstChild;

    innerHtmlSvg.value = svgElem.innerHTML;
    attributesSvg.value.xmlns = svgElem.getAttribute('xmlns')
    attributesSvg.value.viewBox = svgElem.getAttribute('viewBox');
  }
});

</script>

<template>
  <svg v-if="showSvg" v-bind="attributesSvg" v-html="innerHtmlSvg" class="v-icon-flag"></svg>
</template>

<style scoped>
.v-icon-flag{
  width:18px;
  position: relative;
}
.bordered{
  overflow: hidden;
  border-radius: 2px;
}
</style>