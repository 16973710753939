/**
 * @property {array} jsAppRoutes
 */

/**
 * @property {object} jsAppData
 * @property {string} jsAppData.baseUrl
 * @property {string} jsAppData.currentUrl
 * @property {string} jsAppData.currentRouteName
 * @property {string} jsAppData.currentPageName
 * @property {string} jsAppData.userUid
 * @property {string} jsAppData.userEmail
 */

/**
 * @property {array} jsAppTooltips
 */

import {initCommonScripts} from "./common";
import {initRegisterScripts} from "./auth/register";
import {initLoginScripts} from "./auth/login";
import {initResetPasswordScripts} from "./auth/reset-password";
import {initProfileScripts} from "./profile";
import {initInviteFriendScripts} from "./cashback/invite-friend";
import {initCashbackHistoryScripts} from "./cashback/cashback-history";

import {initApiKeysScripts} from "./api/api-keys";

import {initBillingHistoryScripts} from "./billing/billing-history";
import {initBillingSubscriptionScripts} from "./billing/billing-subscriptions";
import {initBillingSettingsScripts} from "./billing/billing-settings";

import {initCartScripts} from "./cart/index.js";
import {initCartScriptsOld} from "./cart/index-old.js";
import {initBuyWebsiteTrafficScripts} from "./cart/buy-website-traffic.js";
import {initBuySeoTrafficScripts} from "./cart/buy-seo-traffic.js";
import {initBuyBacklinksScripts} from "./cart/buy-backlinks.js";
import {initCartDoPayScripts} from "./cart/do-pay";
import {initCartPaymentMethodCryptoScripts} from "./cart/payment_methods/crypto.js";
import {initCartPaymentMethodBankTransferScripts} from './cart/payment_methods/bank-transfer.js';
import {initCartPaymentMethodCommerceGateScripts} from './cart/payment_methods/commerce-gate.js';
import {initCartPaymentMethodFastspringScripts} from './cart/payment_methods/fastspring.js';
// import {initCartPaymentMethodUnlimitScripts} from './cart/payment_methods/unlimit.js';

import {initGuaranteedProjectsScripts} from "./guaranteed/guaranteed-projects.js";
import {initGuaranteedProjectAddScripts} from "./guaranteed/guaranteed-project-add";
import {initGuaranteedProjectEditScripts} from "./guaranteed/guaranteed-project-edit";
import {initGuaranteedProjectEditUnlimScripts} from "./guaranteed/guaranteed-project-edit-unlim";
import {initGuaranteedProjectRenewScripts} from "./guaranteed/guaranteed-project-renew";

import {initSeoClicksStartFreeTrial} from "./seo-traffic/seo-traffic-start-free-trial";
import {initSeoClicksSettingsScripts} from "./seo-traffic/seo-traffic-settings";
import {initSeoTrafficABTestsScripts} from "./seo-traffic/seo-traffic-ab-tests.js";
import {initSeoTrafficABTestScripts} from "./seo-traffic/seo-traffic-ab-test.js";
import {initSeoTrafficABTestAddScripts} from "./seo-traffic/seo-traffic-ab-test-add.js";

import {initBacklinksStartFreeTrial} from "./backlinks/backlinks-start-free-trial";
import {initBacklinksHomepageScripts} from "./backlinks/backlinks-homepage.js";
import {initBacklinksHomepageBuyScripts} from "./backlinks/backlinks-homepage-buy.js";
import {initBacklinksHomepageActivateScripts} from "./backlinks/backlinks-homepage-activate.js";

import {initCalendlyWidget} from "./items/calendly-widget";

export default function route() {
  let args = Array.prototype.slice.call(arguments);
  let name = args.shift();

  if (jsAppRoutes.hasOwnProperty(name)) {
    return '/' + jsAppRoutes[name].split('/').
        map(str => str[0] === '{' ? args.shift() : str).
        join('/');
  }
}

document.addEventListener('DOMContentLoaded', () => {
  console.info('Ready!');
  if (typeof $ !== 'function') {
    console.error('jQuery loading error');
    window.location.href = '/';
  } else {
    $(document).on('ajaxError', function(xhr, error) {
      if (error.status && error.status === 401) {
        if (typeof window.location.reload === 'function') {
          window.location.reload();
        }
      }
    });

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      dataType: 'json',
    });

    initCommonScripts();
    initRegisterScripts();
    initLoginScripts();
    initResetPasswordScripts();
    initProfileScripts();
    initInviteFriendScripts();
    initCashbackHistoryScripts();

    initApiKeysScripts();

    initBillingHistoryScripts();
    initBillingSubscriptionScripts();
    initBillingSettingsScripts();

    if (jsAppData.expSt928 && jsAppData.expSt928 === 'new') {
      initCartScripts();
      initBuyWebsiteTrafficScripts();
      initBuySeoTrafficScripts();
      initBuyBacklinksScripts();
      initCartDoPayScripts();
      initCartPaymentMethodCryptoScripts();
      initCartPaymentMethodBankTransferScripts();
      initCartPaymentMethodCommerceGateScripts();
      initCartPaymentMethodFastspringScripts();
      // initCartPaymentMethodUnlimitScripts();
    } else {
      initCartScriptsOld();
      initBuyWebsiteTrafficScripts();
      initBuySeoTrafficScripts();
      initBuyBacklinksScripts();
      initCartDoPayScripts();
    }

    initGuaranteedProjectsScripts();
    initGuaranteedProjectAddScripts();
    initGuaranteedProjectEditScripts();
    initGuaranteedProjectEditUnlimScripts();
    initGuaranteedProjectRenewScripts();

    initSeoClicksStartFreeTrial();
    initSeoClicksSettingsScripts();
    initSeoTrafficABTestsScripts();
    initSeoTrafficABTestScripts();
    initSeoTrafficABTestAddScripts();

    initBacklinksStartFreeTrial();
    initBacklinksHomepageScripts();
    initBacklinksHomepageBuyScripts();
    initBacklinksHomepageActivateScripts();

    initCalendlyWidget();
  }
});
