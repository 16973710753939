import {runIfHasSelecter} from '../../utils/panel-lib';
import route from '../index.js';

const initScripts = function() {
  const $table = $('#seo-traffic-ab-tests-table');
  const $searchInput = $('.search-box input[type="search"]');
  const $loadingBlock = $('.loading-block');

  $table.dataTable({
    responsive: true,
    processing: true,
    dom: `<'row'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>
            <'row'<'col-sm-12'tr>>
            <'row'<'col-sm-12 col-md-5 d-none'i><'col-12'p>>`,
    order: [[0, 'desc']],
    buttons: [],
    columns: [
      {'width': '10%'},
      {'width': '20%'},
      {'width': '20%'},
      {'width': '10%', orderable: false},
      {'width': '10%', orderable: false},
      {'width': '10%'},
      {'width': '10%'},
      {'width': '10%', orderable: false},
      {'class': 'hidden'},
    ],
    columnDefs: [
      {
        targets: -2,
        render: function(data, type, row) {
          return row[8]
              ? `<a href="${route('seo_traffic.ab_tests.show', row[8])}">Details</a>`
              : '';
        },
      },
    ],
    createdRow: function(row, data) {},
  });

  // Search
  let localStorageSearch;
  try {
    localStorageSearch = window.localStorage.getItem('seo-traffic-ab-tests-table-search');
  } catch (e) {
    console.log('Error localStorage.getItem: ' + e.responseText);
  }

  if (localStorageSearch !== null && localStorageSearch !== 'null' && localStorageSearch) {
    $searchInput.val(localStorageSearch);
  }

  $searchInput.on('keyup search', function() {
    $table.DataTable().search($(this).val()).draw();
    window.localStorage.setItem('seo-traffic-ab-tests-table-search',
        $(this).val());
  });

  updateTable();

  function updateTable() {
    $table.DataTable().search($searchInput.val()).ajax.url(route('api.seo_traffic.ab_tests.get-tests'));

    /** DataTables before receiving the data */
    $table.on('preXhr.dt', function() {
      $loadingBlock.addClass('processing');
    });

    /** DataTables after receiving the data */
    $table.on('xhr.dt', function() {
      $loadingBlock.removeClass('processing');
      $('body').removeClass('loading');
    }).DataTable().ajax.reload();
  }
};

export const initSeoTrafficABTestsScripts = () => {
  runIfHasSelecter('[data-role="seo-traffic-ab-tests"]', [
    initScripts,
  ]);
};
