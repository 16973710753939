import {chatSupportShowMessage} from "../utils/chatWithSupport";
import Rule from "./Rule";

export default class extends Rule{

    constructor(data: any, frontErrors: any, frontErrorsKey: string) {
        super(data,frontErrors,frontErrorsKey);
        this.message = 'Your keyword is too long';
    }

    check(){
        let longKeyword = this.data.find((keyword:any) => keyword.name.length > 255);

        if(longKeyword){
            if(this.getCurrentFrontErrorsMessage() !== this.message){
                chatSupportShowMessage('I\'m getting the error "Your keyword is too long" when I enter the keyword "'+longKeyword+'". Please assist me with creating a project.');
            }
            this.status = false;
            this.setFrontErrorsMessage();
        }else{
            this.status = true;
            this.deleteFrontErrorsMessage();
        }
        return this.status;
    }

}