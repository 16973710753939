<template>
  <input
      :id="id ?? formGroupUid"
      type="text"
      class="form-control"
      :class="isError ? 'invalid' : ''"
      :name="name"
      :placeholder="placeholder"
      v-model="model"
  >
</template>

<script setup>

import {inject} from "vue";

defineProps({
  id: {},
  isError: {},
  name: {},
  placeholder: {},
});

const model = defineModel();

const formGroupUid = inject('formGroupUid','');

</script>

<style lang="scss" scoped>
.form-control{
  border-color:#D6E3EF;
  background-color: #fff;
  font-family: "Inter", sans-serif;
  color:#131D30
}
.form-control:focus{
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control.invalid{
  border-color: #F2001D;
  background-color: #fff;
  color:#131D30;
}
</style>