<template>
  <div class="v-panel-disclosure-button">
    <a class="v-panel-disclosure-button__link" @click="toggle">
      <span><v-icon-svg name="icon_arrow-down"/></span>
      <span>
            <span class="v-panel-disclosure-button__link--to-open">{{ buttonTextOpen }}</span>
            <span class="v-panel-disclosure-button__link--to-close">{{ buttonTextClose }}</span>
        </span>
    </a>
  </div>
</template>

<script>
import VIconSvg from "./VIconSvg.vue";
const classes = {
  closed: 'js-disclosure-closed',
  opened: 'js-disclosure-opened',
  openActive: 'js-disclosure-open-active',
  closeActive: 'js-disclosure-close-active',
}
export default {
  props:{
    wrapper: {type:Object, required:true},
    defaultOpened: {type: Boolean, default: false},
    duration: {type:Number, default: 250},
    buttonTextOpen: {type: String, default: 'Show all'},
    buttonTextClose: {type: String, default: 'Show less'},
  },
  components: {VIconSvg},
  methods:{

    isClosed(){ return this.wrapper.classList.contains(classes.closed); },
    isOpened(){ return this.wrapper.classList.contains(classes.opened); },
    isOpenActive(){ return this.wrapper.classList.contains(classes.openActive); },
    isCloseActive(){ return this.wrapper.classList.contains(classes.closeActive); },
    setClassOpenActive(){ this.wrapper.classList.add(classes.openActive); },
    setClassCloseActive(){ this.wrapper.classList.add(classes.closeActive); },
    getHeightCurrent(){ return this.wrapper.clientHeight; },

    setClassClosed(){
      this.wrapper.classList.remove(classes.opened);
      this.wrapper.classList.add(classes.closed);
    },
    setClassOpened(){
      this.wrapper.classList.remove(classes.closed);
      this.wrapper.classList.add(classes.opened);
    },

    removeClassStatusActive(){
      this.wrapper.classList.remove(classes.openActive);
      this.wrapper.classList.remove(classes.closeActive);
    },
    removeClassStatus(){
      this.wrapper.classList.remove(classes.closed);
      this.wrapper.classList.remove(classes.opened);
    },

    /**
     * @return Boolean true - открываем, false - закрываем
     */
    getActionType(){ return this.isClosed() || this.isOpenActive(); },

    getHeightOpened(){
      let currentClasses = this.wrapper.classList.value;
      let currentStyleHeight = this.wrapper.style.height;

      this.setClassOpened();
      this.wrapper.style.height = 'auto';

      let heightOpened = this.wrapper.clientHeight;

      this.wrapper.classList.value = currentClasses;
      this.wrapper.style.height = currentStyleHeight;

      return heightOpened;
    },
    getHeightClosed(){

      let currentClasses = this.wrapper.classList.value;
      let currentStyleHeight = this.wrapper.style.height;

      this.setClassClosed();
      this.wrapper.style.height = 'auto';

      let heightClosed = this.wrapper.clientHeight;

      this.wrapper.classList.value = currentClasses;
      this.wrapper.style.height = currentStyleHeight;

      return heightClosed;

    },

    toggle(e){

      let actionType = this.getActionType(); //true - open, false - close

      let heightFrom = this.getHeightCurrent();
      let heightTo = actionType ? this.getHeightOpened() : this.getHeightClosed();

      this.wrapper.style.height = heightFrom+'px';
      this.removeClassStatus();
      actionType ? this.setClassOpenActive() : this.setClassCloseActive();

      this.wrapper.addEventListener('transitionend', this.transitionEnd);

      setTimeout(()=>{
        this.wrapper.style.height = heightTo+'px';
      }, 10);

    },
    transitionEnd(e){

      if(e.propertyName !== 'height'){ return; }

      this.wrapper.removeEventListener('transitionend', this.transitionEnd);

      let actionType = this.getActionType();
      this.removeClassStatusActive();
      actionType ? this.setClassOpened() : this.setClassClosed();

      this.wrapper.style.height = '';

    }
  },
  mounted: function(){
    if(this.wrapper){
      this.wrapper.classList.add('v-panel-disclosure-button--wrapper');
      if(this.defaultOpened){
        this.setClassOpened();
      }else{
        this.setClassClosed();
      }
      this.wrapper.style.transitionDuration = this.duration / 1000 + 's';
    }
  }
}
</script>

<style lang="scss">
.v-panel-disclosure-button {
  text-align: center
}

.v-panel-disclosure-button__link {
  align-items: center;
  color: #1c7ee0;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  justify-content: center;
  text-align: center;
  text-decoration: none
}

.v-panel-disclosure-button__link span:first-child{
  margin-right: 9px;
}
.v-panel-disclosure-button__link span:first-child svg{
  width:14px;
  height:auto;
  transform: rotateZ(0deg);
  transition: transform .25s ease;
  position: relative;
  top: -2px;
}
.v-panel-disclosure-button--wrapper.js-disclosure-opened .v-panel-disclosure-button__link span:first-child svg,
.v-panel-disclosure-button--wrapper.js-disclosure-open-active .v-panel-disclosure-button__link span:first-child svg,{
  transform: rotateZ(180deg);
}

.v-panel-disclosure-button--wrapper{
  transition-timing-function: ease;
  transition-property: height;
  transition-duration: 0.25s;
  overflow: hidden;
}
.v-panel-disclosure-button--wrapper.js-disclosure-close-active,
.v-panel-disclosure-button--wrapper.js-disclosure-open-active{
  overflow: hidden;
}

.v-panel-disclosure-button--wrapper.js-disclosure-closed .v-panel-disclosure-button__link--to-open{
  display:inline;
}
.v-panel-disclosure-button--wrapper.js-disclosure-closed .v-panel-disclosure-button__link--to-close{
  display:none;
}
.v-panel-disclosure-button--wrapper:not(.js-disclosure-closed) .v-panel-disclosure-button__link--to-open{
  display:none;
}
.v-panel-disclosure-button--wrapper:not(.js-disclosure-closed) .v-panel-disclosure-button__link--to-close{
  display:inline;
}

</style>