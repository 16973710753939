<template>
  <form @submit.prevent="handleSubmit" class="v-form">
    <slot/>
  </form>
</template>

<script setup>
import {ref, provide, reactive} from "vue";
import {ApiResource} from "../../plugins/ApiExtends";

const props = defineProps({
  data: {},
  method: {},
  action: {},
  frontErrors: {default: {}},
  filterData: {type: Function},
  hookSuccess: {type: Function},
  hookError: {type: Function},
  hookDefault: {type: Function},
});

const formRequestExecuting = ref(false);
const errors = reactive({});
const errorMessage = ref();

const api = reactive(new ApiResource());

const handleSubmit = () => {

  if(Object.keys(props.frontErrors).length > 0){

    formRequestExecuting.value = true;

    setTimeout(() => {
      formRequestExecuting.value = false;
      if(props.hookDefault){props.hookDefault(api.dataRaw);}
      if(props.hookError){props.hookError(api.dataRaw);}
    },500);

    return;
  }

  if(formRequestExecuting.value){return;}

  formRequestExecuting.value = true;

  let params = {};
  if(props.method.toLowerCase() === 'get'){
    params = {
      method: props.method,
      url: props.action,
    }
    if(props.filterData){
      params.params = props.filterData(props.data);
    }
  }else{
    params = {
      method: props.method,
      url: props.action,
    }
    if(props.filterData){
      params.data = props.filterData(props.data);
    }
  }

  api.downloadAsync(params).then(() => {
    formRequestExecuting.value = false;
    let trigger = api.isSuccess();
    if(getQueryParam('form-success') === 'true'){
      trigger = true;
    }else if(getQueryParam('form-success') === 'false'){
      trigger = false;
    }
    if(trigger){
      errors.value = {}
      errorMessage.value = null;
      if(props.hookSuccess){props.hookSuccess(api.dataRaw);}
      if(props.hookDefault){props.hookDefault(api.dataRaw);}
    }else{
      errors.value = api.errorErrors;
      errorMessage.value = api.errorMessage;
      if(props.hookDefault){props.hookDefault(api.dataRaw);}
      if(props.hookError){props.hookError(api.dataRaw);}
    }
  })

};

const getQueryParam = (param) => {

  let uri = window.location.search.substring(1);
  let params = new URLSearchParams(uri);
  return params.get(param);

  // Создаем объект URL, используя текущий адрес страницы
  const url = new URL(window.location.href);
  // Используем URLSearchParams для доступа к параметрам запроса
  const queryParams = url.searchParams;
  // Получаем значение нужного параметра
  return queryParams.get('getQueryParam');
};


// для внутренних элементов формы, чтобы они могли отслеживать состояние запроса
provide('formRequestExecuting', formRequestExecuting);

//для внешних компонент, чтобы они могли отслеживать ошибки и состояние формы
defineExpose({formRequestExecuting, errors, errorMessage})

</script>

<style lang="scss" scoped>
.v-form{}
</style>