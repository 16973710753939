<template>
    <div v-if="show" class="v-transition-slide-hide" ref="wrap">
      <slot/>
    </div>
</template>

<script>
export default {
  props:{
    show: {type:Boolean, required:true}
  },
  data(){
    return {
      wrapHeight: 'auto',
      wrapMaxHeight: 'auto',
    }
  },
  methods: {

  },
  setup() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.v-transition-slide-hide{
}
</style>