import {formValidationErrorClear, formValidationErrorHandling, runIfHasSelecter} from "../../utils/panel-lib.js";

const initCommon = function (currentData, translations) {
    let $apiKeyTable = $('#api-keys-table');
    let $loadingBlock = $('.loading-block');
    let $searchInput = $('.search-box input[type="search"]');

    $apiKeyTable.dataTable({
        responsive: true,
        processing: true,
        dom: "<'row'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 d-none'i><'col-12'p>>",
        order: [[0, "desc"]],
        language: {
            emptyTable: translations.dataTable.emptyTable,
            lengthMenu: translations.dataTable.lengthMenu,
            zeroRecords: translations.dataTable.zeroRecords,
            info: translations.dataTable.info,
            infoEmpty: translations.dataTable.infoEmpty,
            infoFiltered: translations.dataTable.infoFiltered,
            loadingRecords: translations.dataTable.loadingRecords,
            processing: translations.dataTable.processing,
            searchPlaceholder: translations.dataTable.searchPlaceholder,
            aria: {
                sortAscending: ": " + translations.sortAscending,
                sortDescending: ": " + translations.sortAscending,
            },
            paginate: {
                first: '<i class="fas fa-arrow-to-left"></i>',
                last: '<i class="fas fa-arrow-to-right"></i>',
                next: '<i class="fas fa-chevron-right"></i>',
                previous: '<i class="fas fa-chevron-left"></i>'
            },
        },
        buttons: [],
        columns: [
            {"width": "25%"},
            {"width": "25%"},
            {"width": "40%"},
            {"width": "10%", orderable: false},
        ],
        columnDefs: [
            {
                targets: -1,
                render: function(data, type, row) {
                    return '<button class="button button-link-dashed" type="button" data-bs-toggle="modal" data-bs-target="#confirm-delete" data-id="' + row[0] + '"><span>Delete</span></button>';
                },
            },
            {
                targets: -2,
                render: function(data, type, row) {
                    let apiKey = row[2];
                    let charCount = apiKey.length;
                    let asterisks = '';

                    let asterisksCount = charCount - 6;
                    if (asterisksCount > 6) {
                        for (let i = 0; i < asterisksCount; i++) {
                            asterisks += '*'
                        }

                        apiKey = asterisks + row[2].substring(asterisksCount);
                    }

                    return apiKey;
                },
            },
            {
                targets: -3,
                render: function(data, type, row) {
                    return row[3];
                },
            },
            {
                targets: -4,
                render: function(data, type, row) {
                    return row[1];
                },
            },
        ],
        createdRow: function (row, data) {
        }
    });

    function updateApiKeys() {
        const url = '/api/user/api-keys-table';

        $apiKeyTable.DataTable().search($searchInput.val()).ajax.url(url);

        /** DataTables before receiving the data */
        $apiKeyTable.on('preXhr.dt', function () {
            $loadingBlock.addClass('processing');
        });

        /** DataTables after receiving the data */
        $apiKeyTable.on('xhr.dt', function (e, settings, data) {
            if (typeof data.data !== 'undefined' && data.data.length > 0) {
                $('.no-api-keys').hide();
                $('.st-panel-hdr').show();
                $('.table-api-keys').show();
            } else {
                $('.no-api-keys').show();
                $('.st-panel-hdr').hide();
                $('.table-api-keys').hide();
            }

            $loadingBlock.removeClass('processing');
            $('body').removeClass('loading');
        }).DataTable().ajax.reload();
    }

    $('#add-api-key')
        .on('show.bs.modal', function () {
            let $modal = $(this);
            let $modalBody = $modal.find('.modal-body');
            let $modalFooter = $modal.find('.modal-footer');
            $modalBody.find('form').show();
            $modalBody.find('.created-api-key').html('').hide();
            $modalBody.find('[name="title"]').val('');
            $modalFooter.find('[data-bs-dismiss="modal"]').addClass('me-sm-3').html('Cancel');
            $modalFooter.find('.btn-ok').show();
        })
        .find('.btn-ok')
        .on('click', function(e) {
            e.preventDefault();

            let $button = $(this);
            let $modal = $button.closest('.modal');
            let $form = $modal.find('form');
            let data = {
                title: $('[name="title"]').val()
            }

            $form.find('.error-box').html('').hide();
            $button.prop('disabled', true);

            $.ajax({
                url: '/api/user/api-key/add',
                method: 'POST',
                data: data,
                dataType: 'json',
                success: function (response) {
                    let data = (response.data ? response.data : []);

                    if (response.success) {
                        let $modalBody = $modal.find('.modal-body');
                        let $modalFooter = $modal.find('.modal-footer');
                        // $modal.find('[data-bs-dismiss="modal"]').trigger('click');
                        $modalBody.find('form').hide();
                        $modalBody.find('.created-api-key').html('Your new API Key: ' + (data.apiKey ? data.apiKey : '')).show();
                        $modalFooter.find('[data-bs-dismiss="modal"]').removeClass('me-sm-3').html('Close Window');
                        $modalFooter.find('.btn-ok').hide();

                        $('.no-api-keys').hide();
                        $('.st-panel-hdr').show();
                        $('.table-api-keys').show();

                        updateApiKeys();
                        formValidationErrorClear($form);
                    } else {
                        if (response.message) {
                            $form.find('.error-box').html(response.message).show();
                        }

                        if (data.errorsForm) {
                            formValidationErrorHandling($form, data.errorsForm);
                        }
                    }

                    $button.prop('disabled', false);
                },
                error: function () {
                    $form.find('.error-box').html('An error occurred while saving').show();
                    $button.prop('disabled', false);
                }
            });
        });

    $('#confirm-delete')
        .on('show.bs.modal', function (e) {
            $(this).find('.btn-ok').data('id', $(e.relatedTarget).data('id'));
        })
        .find('.btn-ok')
        .on('click', function (e) {
            e.preventDefault();

            let $button = $(this);
            let $modal = $button.closest('.modal-content');
            let data = {
                id: $button.data('id')
            }

            formValidationErrorClear($modal);
            $button.prop('disabled', true);

            $.ajax({
                url: '/api/user/api-key/delete',
                method: 'DELETE',
                data: data,
                dataType: 'json',
                success: function (response) {
                    if (response.success) {
                        $modal.find('[data-bs-dismiss="modal"]').trigger('click');
                        updateApiKeys();
                    } else {
                        if (response.message) {
                            $modal.find('.error-box').html(response.message).show();
                        }
                    }

                    $button.prop('disabled', false);
                },
                error: function () {
                    $modal.find('.error-box').html('An error occurred while saving').show();
                    $button.prop('disabled', false);
                }
            });
        });

    // Search
    let localStorageSearch;
    try {
        localStorageSearch = window.localStorage.getItem('st-api-keys-table-search');
    } catch (e) {
        console.log('Error localStorage.getItem: ' + e.responseText);
    }

    if (localStorageSearch !== null && localStorageSearch !== 'null' && localStorageSearch) {
        $searchInput.val(localStorageSearch);
    }

    $searchInput.on('keyup search', function () {
        $apiKeyTable.DataTable().search($(this).val()).draw();
        window.localStorage.setItem('st-api-keys-table-search', $(this).val());
    });

    updateApiKeys();
}

export const initApiKeysScripts = function () {
    runIfHasSelecter('[data-role="api-keys"]', [
        initCommon
    ]);
}
