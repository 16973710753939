<template>
  <div class="st-panel st-panel_bg_white" :class="classPanel" ref="panel">
    <div class="st-panel-container">
      <div class="content">

        <div class="st-panel-hdr" v-if="title">
          <h2>{{title}}</h2>
        </div>

        <slot name="content" v-if="!loadingAnimation || showContentWhenLoading"/>

        <v-lottie-load v-if="loadingAnimation"/>

        <div class="st-panel__bottom" v-if="disclosureOn && (!loadingAnimation || showContentWhenLoading)">
          <v-panel-disclosure-button
              :wrapper="$refs.panel" :default-opened="disclosureDefaultOpen"
              :button-text-close="disclosureButtonTextClose"
              :button-text-open="disclosureButtonTextOpen"
              :duration="disclosureDuration"
          />
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import VLottieLoad from "./VLottieLoad.vue";
import VPanelDisclosureButton from "./VPanelDisclosureButton.vue";

export default {
  components:{
    VLottieLoad,
    VPanelDisclosureButton
  },
  props:{
    classPanel: {Type:String},
    title: {Type:String},
    loadingAnimation: {Type: Boolean, default:false},
    showContentWhenLoading: {Type: Boolean, default:false},

    disclosureOn: {Type: Boolean, default:false},
    disclosureDefaultOpen: {Type: Boolean, default:false},
    disclosureButtonTextOpen: {Type: String},
    disclosureButtonTextClose: {Type: String},
    disclosureDuration: {type:Number},
  }
}
</script>

<style scoped>
.st-panel__bottom{
  margin-top: 24px;
  margin-bottom: 0px;
}
</style>